import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { MEDIA_MIN_MEDIUM } from "../constants"
import Layout from "../components/Layout"
import Item from "../components/cart/Item"
import Totals from "../components/cart/Totals"
import { useLocation } from "@reach/router"
import { BrinkContext } from "../components/context/BrinkContext"
import * as events from "../components/context/utils/events"

const Container = styled.div`
  max-width: 70rem;
  margin: 5rem auto 0;
  padding: 0;
`

const PageTitle = styled.h1`
  padding: 4rem 3rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  ${MEDIA_MIN_MEDIUM} {
    padding: 6rem 3rem 0;
  }

  i {
    color: ${(p) => p.theme.colors.success};
    font-size: 3rem;
    margin: 0.4rem 1rem 0 0;
    display: block;
  }
`

const PageSubTitle = styled.p`
  text-align: center;
  padding: 0;
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-top: 0;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 1.6rem;
  }
`

const GeneralInfo = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 70rem;
  margin: 4rem auto 6rem;
  background: ${(p) => p.theme.colors.black};
  color: ${(p) => p.theme.colors.white};
  padding: 2rem 3rem 1.8rem;
`

const Box = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-between;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

  &:first-child {
    border: none;
  }
`

const Title = styled.p`
  text-transform: uppercase;
  font-size: 1.3rem;
  display: inline-block;

  ${MEDIA_MIN_MEDIUM} {
    display: block;
  }
`

const Value = styled.p`
  font-size: 1.3rem;
  text-transform: uppercase;
  display: inline-block;
`

const Summary = styled.div`
  position: relative;
  border-top: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
  padding: 2rem 0 2rem;
  margin: 0 3rem 4rem;

  ${MEDIA_MIN_MEDIUM} {
    border: 0.1rem solid ${(p) => p.theme.colors.lightBorder};
    padding: 2rem 3rem 2rem;
    margin: 0 auto 6rem;
  }

  h3 {
    position: absolute;
    background: ${(p) => p.theme.colors.white};
    top: -2.1rem;
    padding: 0 1rem;
    left: 50%;
    transform: translate(-50%, 0);
    font-size: 1.4rem;
    white-space: nowrap;

    ${MEDIA_MIN_MEDIUM} {
      left: auto;
      transform: translate(0, 0);
    }
  }
`

const Items = styled.div`
  width: 100%;
`

const CartTotals = styled(Totals)`
  > div {
    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: 0.1rem solid ${(p) => p.theme.colors.lightBorder};

      ${MEDIA_MIN_MEDIUM} {
        border-bottom: none;
      }
    }
  }
`
const SuccessPage = ({ pageContext }) => {
  const {
    discountCode,
    currentStore,
    shippingAddress,
    deleteDiscountCode,
    deleteDiscountCodePopup,
    listName
  } = useContext(BrinkContext)
  const { t } = useTranslation("translations")

  const useMountEffect = (fun) =>
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(fun, [])

  useMountEffect(() => {
    events.purchase({
      billingAddress,
      shippingAddress,
      order,
      discountCode,
      cart,
      currentStore,
      listName
    })
    deleteDiscountCode()
    deleteDiscountCodePopup()
  })

  const location = useLocation()

  if (!location.state) return null

  const { order, cart, billingAddress } = location.state

  return (
    <Layout
      meta={{ title: "Rondure" }}
      invertedHeader
      hideCountrySelector
      pageContext={pageContext}
    >
      <Container>
        <PageTitle>
          <i className="fal fa-check-circle"></i>
          {t("Thank you")}
        </PageTitle>
        <PageSubTitle>{t("Your order has been received.")}</PageSubTitle>
        <GeneralInfo>
          <Box>
            <Title>{t("Order number")}:</Title>
            <Value>{order.reference}</Value>
          </Box>
          <Box>
            <Title>{t("Date")}:</Title>
            <Value>{dayjs().format("YYYY-MM-DD")}</Value>
          </Box>
          <Box>
            <Title>{t("Payment method")}:</Title>
            <Value>{t(`Payment method ${order.paymentMethod}`)}</Value>
          </Box>
        </GeneralInfo>
        <Summary>
          <h3>{t("Order summary")}</h3>
          <Items>
            {cart.cartItems.map((item) => (
              <Item item={item} key={item.id} showControls={false} />
            ))}
          </Items>
          <CartTotals />
        </Summary>
      </Container>
    </Layout>
  )
}

export default SuccessPage
